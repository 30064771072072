.splash {
  height: 350px;
  overflow: hidden;
  display: block;
  position: relative;
  margin-bottom: 10px;
}

.splash .overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(36, 36, 36, 0.6) !important;
  color: white;
  text-align: center;
  line-height: 250px;
}

.splash .overlay h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.splash img {
  margin-top: 0;
  width: 100%;
}

.splash .title {
  color: white;
}