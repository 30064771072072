.main {
  background: white;
  height: 100%;
}

// /* XS styling */
// @media (max-width: @screen-xs-max) {
//   .container {
//     width: inherit;
//   }
// }

// /* SM styling */
// @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
//   .container {
//     width: inherit;
//   }
// }