body {
  font-family: 'Nunito Sans', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Roboto', serif;
}

blockquote {
  font-family: 'Roboto', sans-serif !important;
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px 1.5em 0px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  width: 90%;
}

blockquote:before {
  font-family: 'Roboto', sans-serif;
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}